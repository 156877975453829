export const MainColor = '#f60';
export const Blue = '#2F58CD';
export const Gray = '#bdbdbd';
export const Red = '#ed1c25';
export const Orange = '#FFDF00';
export const Green = '#1dd1a1';
export const Black = '#333333';
export const Light = '#e0e0e0';
export const LightWhite = '#f6f6f6';
export const White = '#ffffff';
