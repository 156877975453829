import React from 'react';

function MenuOne() {
  return (
    <div>
      <div className="col-l" style="height: auto !important;">
        <div className="link-du-doan">
          <span className="">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clblue"
              href="https://xsmn247.me/xsmb-sxmb-kqxsmb-xstd-xshn-ket-qua-xo-so-mien-bac.html"
              title="XSMB"
              rel="dofollow"
              target="_blank"
            >
              XSMB
            </a>
          </span>
          <span className="">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clblue"
              href="https://az24.vn/xsmt-sxmt-xo-so-mien-trung.html"
              title="SXMT"
              rel="dofollow"
              target="_blank"
            >
              SXMT
            </a>
          </span>
          <span className=" dsp-mobile">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clnote"
              href="/redirect/out?token=reOJY37EBwQZknugvFXH%2Bml752uSiM12SF2tW47yG8I%3D"
              title="Cầu Vip Hôm Nay"
              rel="nofollow"
              target="_blank"
            >
              Cầu Vip Hôm Nay
            </a>
          </span>
          <span className=" dsp-mobile">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clnote"
              href="/redirect/out?token=sKea1ooB24Xms0rVvjtFBjbZifB04gekNSDINWvbBAQ%3D"
              title="Cầu Chuẩn Miền Bắc"
              rel="nofollow"
              target="_blank"
            >
              Cầu Chuẩn Miền Bắc
            </a>
          </span>
          <span className=" dsp-mobile">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clnote"
              href="/redirect/out?token=31GM6aAwfFMIbhWsRyqjMUtdRbS%2F0Vov%2F6CwEvkUV%2FQ%3D"
              title="Số VIP 3 miền"
              rel="nofollow"
              target="_blank"
            >
              Số VIP 3 miền
            </a>
          </span>
          <span className=" dsp-mobile">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clnote"
              href="/redirect/out?token=lYpIJu4sg3r%2BALhOiKmTpY5K7a2RPz7kfhyrLVw97R8%3D"
              title="Thông tin tuyệt mật"
              rel="nofollow"
              target="_blank"
            >
              Thông tin tuyệt mật
            </a>
          </span>
          <span className=" dsp-mobile">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clnote"
              href="/redirect/out?token=QluGqhpZn9ATSXqIO%2FTXWm5G4aO%2BZsFnLovhw29dt7k%3D"
              title="Xổ số lộc phát"
              rel="nofollow"
              target="_blank"
            >
              Xổ số lộc phát
            </a>
          </span>
          <span className=" dsp-mobile">
            <img src="/css/images/hot2.gif" width="22" height="11" alt="hot" />
            <a
              className=" clnote"
              href="/redirect/out?token=vAB56X29mswiaTCGd3lGIhGYsYZcwUS3y76ZpHeI0BQ%3D"
              title="Soi Cầu Tuyệt Mật"
              rel="nofollow"
              target="_blank"
            >
              Soi Cầu Tuyệt Mật
            </a>
          </span>
        </div>

        <div className="box info-city">
          <h2 className="tit-mien">
            <strong>Kết quả xổ số điện toán ngày hôm nay</strong>
          </h2>
          <ul className="dientoan clearfix">
            <li className="first">
              <h2 className="pad10-5">
                <strong>Kết quả xổ số điện toán 123 ngày 12/08/2023</strong>
              </h2>
              <div>
                <span>5</span>
                <span>30</span>
                <span>733</span>
              </div>
            </li>
            <li className="second">
              <h2 className="pad10-5">
                <strong>Kết quả xổ số thần tài ngày 12/08/2023</strong>
              </h2>
              <div>
                <span>9999</span>
              </div>
            </li>
            <li className="last">
              <h2 className="pad10-5">
                <strong>Kết quả xổ số điện toán 6x36 ngày 12/08/2023</strong>
              </h2>
              <div>
                <span>03</span>
                <span>10</span>
                <span>12</span>
                <span>13</span>
                <span>20</span>
                <span>22</span>
              </div>
            </li>
          </ul>

          <div className="see-more">
            <ul className="list-html-link two-column">
              <li>
                Xem thêm
                <a
                  href="https://xoso.mobi/thong-ke-lo-gan-xo-so-mien-bac-xsmb.html"
                  title="thống kê lô gan miền Bắc"
                >
                  thống kê lô gan miền Bắc
                </a>
              </li>
              <li>
                Xem thêm
                <a
                  href="/thong-ke-giai-dac-biet-xo-so-mien-bac-xsmb.html"
                  title="thống kê giải đặc biệt miền Bắc"
                >
                  thống kê giải đặc biệt miền Bắc
                </a>
              </li>
              <li>
                Xem cao thủ
                <a
                  href="https://xoso.mobi/du-doan-ket-qua-xo-so-mien-bac-xsmb-c228.html"
                  title="dự đoán miền Bắc"
                >
                  dự đoán miền Bắc
                </a>
                hôm nay chính xác nhất
              </li>
              <li>
                Tham gia
                <a
                  href="https://xoso.mobi/quay-thu-xsmb-quay-thu-xo-so-mien-bac.html"
                  title="quay thử miền Bắc"
                >
                  quay thử miền Bắc
                </a>
                để thử vận may
              </li>
              <li>
                Hãy soi
                <a href="https://xoso.mobi/soi-cau-bach-thu-lo-to-xsmb-hom-nay.html">
                  cầu bạch thủ miền Bắc
                </a>
                để chọn bộ số ngon nhất
              </li>
              <li>
                Xem thêm bảng kết quả
                <a href="https://xoso.mobi/xsmb-30-ngay-so-ket-qua-xo-so-kien-thiet-mien-bac.html">
                  XSMB 30 ngày
                </a>
              </li>
              <li>
                Mời bạn xem
                <a href="/xsmb-sxmb-xstd-xshn-kqxsmb-ket-qua-xo-so-mien-bac.html">
                  xổ số miền Bắc hôm nay
                </a>
                từ trường quay
              </li>
            </ul>
          </div>
        </div>
        <div className="box box-html" style="height: auto !important;">
          <h2>&nbsp;</h2>

          <p>
            <a
              href="https://xoso.mobi/ket-qua-xo-so-dien-toan.html"
              title="Xổ số điện toán"
            >
              <span style="color:#0000FF">
                <strong>Xổ số điện toán</strong>
              </span>
            </a>
            còn được gọi là xổ số điện tử là một trò chơi cho phép người tham
            gia được tự chọn một dãy số trong giới hạn để dự thưởng mà không
            phải là vé in sẵn dãy số như xổ số truyền thống.
          </p>

          <h2>
            <strong>Cách chơi xổ số điện toán như thế nào?&nbsp;</strong>
          </h2>

          <p>
            Khi tham gia
            <a href="https://xoso.mobi" title="XS">
              <span style="color:#0000FF">
                <strong>XS</strong>
              </span>
            </a>
            điện tử người chơi được phép chọn số tùy thích, có thêm nhiều lựa
            chọn mua vé qua internet hay thiết bị di động, đặc biệt là giải
            thưởng của xổ số điện toán sẽ được cộng dồn cho đến khi có người
            nhận thưởng giúp gia tăng giá trị thưởng của người dùng.&nbsp;
          </p>

          <h2>
            <strong>XS điện toán hôm nay do công ty nào phát hành?</strong>
          </h2>

          <p>
            Hiện nay, vé số điện toán miền bắc do Công ty XSKT Thủ Đô Hà Nội
            phát hành trên địa bàn, trò chơi này đã được đưa ra thị trường từ
            lâu, và không phải là
            <strong>
              <a
                href="https://xoso.mobi/kq-xs-vietlott-truc-tiep-ket-qua-xo-so-vietlott-hom-nay.html"
                title="Xổ số tự chọn Vietlott"
              >
                <span style="color:#0000FF">xổ số tự chọn Vietlott</span>
              </a>
            </strong>
            .
          </p>

          <p>Kết quả xổ số điện toán miền bắc được quay lúc mấy giờ?</p>

          <p>
            Thời gian quay số mở thưởng KQXS điện toán được tiến hành tại trường
            quay số của Công ty xổ số Thủ Đô vào lúc 18h5p, ngay trước khi mở
            thưởng xổ số miền bắc.
          </p>

          <h2>&nbsp;</h2>
        </div>
        <script></script>
      </div>
    </div>
  );
}

export default MenuOne;
