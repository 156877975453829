import React from 'react';

function Footer() {
  return (
    <div style={{ backgroundColor: '#eeeeee', marginTop: 10, padding: 20 }}>
      <p style={{ textAlign: 'center' }}>© Copyright 2019 XSTHIN</p>
    </div>
  );
}

export default Footer;
